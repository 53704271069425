
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
  nextTick,
} from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { wordingCompare } from "@/API/program";
export default defineComponent({
  name: "Wording Compare",
  setup() {
    const pdfWidth = ref<string | undefined>("");
    const pdfHeight = ref<string | undefined>("");

    const calculatePdfBox = () => {
      const target = document.getElementById("preview-box-com");
      const height =
        target?.clientHeight && target?.clientHeight > 600
          ? target?.clientHeight
          : 600;
      pdfHeight.value = `${height}px`;
      pdfWidth.value = `${target?.clientWidth}px`;
    };
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      compareData: "",
      updateData: "",
    });
    const pdfUrl = ref("");
    const init = () => {
      const params = {
        programId: store.state.pgmData.programId,
        type: "External",
        lang: "en",
      };
      if (
        sessionStorage.getItem("params") &&
        sessionStorage.getItem("params") !== null
      ) {
        Object.assign(
          params,
          JSON.parse(sessionStorage.getItem("params") as string)
        );
      }
      wordingCompare({ params: params }).then((res) => {
        data.compareData = res.original;
        data.updateData = res.editable;
        nextTick(() => {
          calculatePdfBox();
        });
      });
      const config: any = {
        url: "/pgapi/program/wording/preview_pdf",
        method: "get",
        params: { ...params, image: false },
        responseType: "blob",
      };
      axios(config).then((res) => {
        // pdfUrl.value = URL.createObjectURL(res.data);
        pdfUrl.value = `${URL.createObjectURL(res.data)}#toolbar=0`;
      });
    };
    const isFixed = ref(false);
    const screenHeight = ref(window.screen.width);
    const mainWidth = ref<number>(0);
    const leftWidth = ref<number>(0);
    store.commit("updatedHeight", screenHeight.value);
    const resizeHandler = (): void => {
      const clientWidth: number = document.body.clientWidth;
      const sideNavWidth: number = (
        document.querySelector(".side-nav-left") as HTMLDivElement
      ).offsetWidth;
      const W = clientWidth - sideNavWidth - 50;

      leftWidth.value =
        (document.querySelector(".preview-box-com-area") as HTMLDivElement)
          .offsetWidth * 2;
      mainWidth.value = W;
    };

    onMounted(() => {
      init();
      window.addEventListener("scroll", () => {
        isFixed.value = document.documentElement.scrollTop > 100;
      });
      try {
        window.addEventListener("resize", resizeHandler);
        window.addEventListener("resize", calculatePdfBox);
        resizeHandler();
      } catch (e) {
        console.log(e);
      }
    });
    return {
      isFixed,
      ...toRefs(data),
      mainWidth,
      leftWidth,
      pdfUrl,
      pdfWidth,
      pdfHeight,
    };
  },
});
